import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_byoc_details_sinch_general = _resolveComponent("byoc-details-sinch-general")!
  const _component_byoc_details_linked_numbers = _resolveComponent("byoc-details-linked-numbers")!
  const _component_byoc_details_page = _resolveComponent("byoc-details-page")!

  return (_openBlock(), _createBlock(_component_byoc_details_page, {
    breadcrumbs: _ctx.breadcrumbs,
    provider: _ctx.provider
  }, {
    default: _withCtx(() => [
      _createVNode(_component_byoc_details_sinch_general, { provider: _ctx.provider }, null, 8, ["provider"]),
      _createVNode(_component_byoc_details_linked_numbers)
    ]),
    _: 1
  }, 8, ["breadcrumbs", "provider"]))
}