
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import ByocDetailsDisconnectProvider from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsDisconnectProvider.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { ProviderType } from '@/definitions/services/byoc/types'

export default defineComponent({
  components: {
    ChatsSettingsPage,
    DetailsHero,
    InfoCards,
    ByocDetailsDisconnectProvider,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbsLink[]>,
    },
    provider: {
      type: Object as PropType<ProviderType>,
    },
  },
})
