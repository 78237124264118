import type { ProviderType } from '@/definitions/services/byoc/types'

export const providerSinch: ProviderType = {
  label: 'Sinch Production',
  logo: require('@/assets/images/byoc/short-logos/sinch.svg'),
  added: '14 Sep at 12:16 pm',
  projectId: 'My first project',
  accessKeyId: '5324d5f0-9bd6-4058-b826-b05a0432f0ea',
  servicePlanId: '0196eeb8ec6b467c94c31b5650a35f86',
  callbackUrl: 'https://my.textmagic.com/webhook/byoc/sinch/sms/incoming',
}

export const providerVonage: ProviderType = {
  label: 'Vonage',
  logo: require('@/assets/images/byoc/short-logos/vonage.svg'),
  added: '07 Sep at 12:16 pm',
  apiKey: '5324d5f',
}

export const providerInfobip: ProviderType = {
  label: 'Infobip',
  logo: require('@/assets/images/byoc/short-logos/infobip.svg'),
  added: '07 Sep at 12:16 pm',
  apiKey: '5324d5f',
}

export const providerBandwidth: ProviderType = {
  label: 'Bandwidth Production',
  logo: require('@/assets/images/byoc/short-logos/bandwidth2.svg'),
  added: '14 Sep at 12:16 pm',
  accountId: '87632',
  username: 'fd14e1f1-713e-46d6-808a-a6438500bd0c',
}

export const providerTwilio: ProviderType = {
  label: 'Twilio Production',
  logo: require('@/assets/images/byoc/short-logos/twilio2.svg'),
  added: '14 Sep at 12:16 pm',
  sId: '5324d5f0-9bd6-4058-b826-b05a0432f0ea',
}
