
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { NumbersMock } from '@/definitions/services/byoc/data'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    LinkNumbersTable,
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { openModal } = useModals()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label in Textmagic', value: 'label-slot' },
      { text: 'Calls', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Forward calls to', value: 'forward-slot' },
    ])
    const tableItems = ref(NumbersMock)

    return {
      tableItems,
      tableHeaders,
      openModal,
    }
  },
})
