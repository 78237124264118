
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { useByoc } from '@/compositions/services/useByoc'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { openDisconnectProviderModal } = useByoc()

    return {
      openDisconnectProviderModal,
    }
  },
})
