import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_byoc_details_disconnect_provider = _resolveComponent("byoc-details-disconnect-provider")!
  const _component_info_cards = _resolveComponent("info-cards")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, { breadcrumbs: _ctx.breadcrumbs }, {
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        pattern: "blue",
        class: "mb-4",
        title: _ctx.provider.label,
        "avatar-url": _ctx.provider.logo,
        "avatar-color": "white"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Added on " + _toDisplayString(_ctx.provider.added), 1)
        ]),
        _: 1
      }, 8, ["title", "avatar-url"]),
      _createVNode(_component_info_cards, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_byoc_details_disconnect_provider)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}